import { Box, Typography } from '@mui/material';
import { Order } from 'public-contracts';
import { useMemo } from 'react';
import {
  Create,
  DateTimeInput,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  required,
  useGetIdentity,
  useInput,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { FormDebug } from '../components/forms/FormDebug';
import { CustomerInput } from './components/CustomerInput';
import { ItemsGridInput } from './components/ItemsGridInput';
import { OrderTotalField } from './components/OrderTotalField';
import { PointsRedemption } from './components/PointsRedemption';
import { TableSelectionInput } from './components/TableSelectionInput';
import { useTableStatus } from './hooks/useTableStatus';

interface OrderFormData {
  reference: string;
  promotionType: 'none' | 'percentage' | 'fixed';
  promotionValue: number;
  customerPoints: number | null;
  pointsRedemption: number;
  orderType: 'dine_in' | 'takeaway' | 'delivery';
  tableNumber?: number;
  items: Array<{
    productId: string;
    quantity: number;
    price: number;
    note?: string;
  }>;
  date: Date;
  customerId?: string;
  status?: 'ordered' | 'ready' | 'delivered' | 'cancelled';
  hasCheckedIn: boolean;
  tempCustomerId?: string;
}

const OrderCreateToolbar = () => {
  const { getValues } = useFormContext();
  const translate = useTranslate();

  const values = getValues();
  const isDineIn = values.orderType === 'dine_in';
  const hasTable = Boolean(values.tableNumber);
  const hasItems = values.items && values.items.length > 0;
  const hasSelectedCustomer = Boolean(values.tempCustomerId);
  const hasCheckedIn = values.hasCheckedIn;

  // Basic requirements: must have items and table (if dine-in)
  const isValid = hasItems && (!isDineIn || (isDineIn && hasTable));

  // If a customer is selected, they must be verified
  const isCustomerValid = hasSelectedCustomer ? hasCheckedIn : true;

  let errorMessage;
  if (!hasItems) {
    errorMessage = translate('resources.orders.messages.selectProducts');
  } else if (isDineIn && !hasTable) {
    errorMessage = translate('resources.orders.messages.selectTableFirst');
  } else if (hasSelectedCustomer && !hasCheckedIn) {
    errorMessage = translate('resources.orders.messages.verifyCustomerFirst');
  }

  return (
    <Toolbar>
      <SaveButton
        disabled={!(isValid && isCustomerValid)}
        label={errorMessage}
      />
    </Toolbar>
  );
};

const generateReference = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear().toString().slice(-2);
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return `ORD${year}${month}${day}${hours}${minutes}${seconds}`;
};

const PromotionSection = () => {
  const translate = useTranslate();
  const { field: promotionTypeField } = useInput({ source: 'promotionType' });
  const { field: customerPointsField } = useInput({ source: 'customerPoints' });
  const { field: itemsField } = useInput({ source: 'items' });

  const totalAmount = (itemsField.value || []).reduce(
    (sum: number, item: { price: number; quantity: number }) =>
      sum + item.price * item.quantity,
    0
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">
        {translate('resources.orders.fields.promotion')}
      </Typography>
      <SelectInput
        source="promotionType"
        label="resources.orders.fields.promotionType"
        defaultValue="none"
        fullWidth
        choices={[
          {
            id: 'none',
            name: translate('resources.orders.promotion.none'),
          },
          {
            id: 'percentage',
            name: translate('resources.orders.promotion.percentage'),
          },
          {
            id: 'fixed',
            name: translate('resources.orders.promotion.fixed'),
          },
        ]}
      />
      {promotionTypeField.value !== 'none' && (
        <NumberInput
          source="promotionValue"
          label="resources.orders.fields.promotionValue"
          fullWidth
          min={0}
          validate={(value) => {
            if (promotionTypeField.value === 'percentage' && value > 100) {
              return translate('resources.orders.messages.percentageExceeded');
            }
            if (promotionTypeField.value === 'fixed' && value > totalAmount) {
              return translate('resources.orders.messages.fixedAmountExceeded');
            }
          }}
        />
      )}

      {promotionTypeField.value === 'none' && (
        <PointsRedemption
          customerPoints={Number(customerPointsField.value || 0)}
          totalAmount={totalAmount}
        />
      )}
    </Box>
  );
};

const OrderForm = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();
  const reference = useMemo(() => generateReference(), []);

  return (
    <SimpleForm
      toolbar={<OrderCreateToolbar />}
      defaultValues={{
        reference,
        promotionType: 'none',
        promotionValue: 0,
        customerPoints: null,
        pointsRedemption: 0,
        orderType: 'dine_in',
        tableNumber: undefined,
        items: [],
        date: new Date(),
        status: 'ordered',
        customerId: null,
        hasCheckedIn: false,
        tempCustomerId: null,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="textSecondary">
          {translate('resources.orders.fields.reference')}: {reference}
        </Typography>
      </Box>
      <SelectInput
        source="orderType"
        label="resources.orders.fields.orderType"
        choices={[
          {
            id: 'dine_in',
            name: translate('resources.orders.orderTypes.dineIn'),
          },
          {
            id: 'takeaway',
            name: translate('resources.orders.orderTypes.takeaway'),
          },
          {
            id: 'delivery',
            name: translate('resources.orders.orderTypes.delivery'),
          },
        ]}
        validate={required()}
      />
      <TableSelectionInput />
      <CustomerInput />
      <DateTimeInput
        source="date"
        label="resources.orders.fields.date"
        defaultValue={new Date()}
        validate={required()}
      />
      <ItemsGridInput />
      <OrderTotalField />
      <PromotionSection />
      <FormDebug enabled={process.env.NODE_ENV === 'development'} />
    </SimpleForm>
  );
};

export const OrderCreate = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { occupyTable } = useTableStatus();

  const transform = (data: OrderFormData) => {
    const items = data.items.map((item) => ({
      productId: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    // Validate table number for dine-in orders
    if (data.orderType === 'dine_in' && !data.tableNumber) {
      throw new Error('Table number is required for dine-in orders');
    }

    // Create base order data
    const orderData = {
      ...data,
      reference: generateReference(), // Generate reference at submission time
      items,
      status: 'ordered',
      pointsRedemption: data.pointsRedemption || 0,
      customerId: data.customerId || null,
      customerPoints: data.customerPoints || 0,
    };

    // Remove tableNumber if not dine-in
    if (data.orderType !== 'dine_in') {
      delete orderData.tableNumber;
    }

    return orderData;
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{
        onSuccess: (data: Order) => {
          // Update table status if it's a dine-in order
          if (data.orderType === 'dine_in' && data.tableNumber) {
            occupyTable(data.tableNumber, data.id);
          }
          redirect('list', 'orders');
        },
        onError: (error: any) => {
          notify(error.message, { type: 'error' });
        },
      }}
    >
      <OrderForm />
    </Create>
  );
};

export default OrderCreate;

import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useRecordContext,
  useTranslate,
  SaveButton,
  Toolbar,
  useNotify,
  useUpdate,
  useRedirect,
  useRefresh,
  FormDataConsumer,
  useGetOne,
  useInput,
} from 'react-admin';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Customer } from '../types';
import { OrderTotalField } from './components/OrderTotalField';
import { ItemsGridInput } from './components/ItemsGridInput';
import { PrintBillButton } from './components/PrintBillButton';
import { useTableStatus } from './hooks/useTableStatus';
import { useEffect, useState } from 'react';
import { CustomerInput } from './components/CustomerInput';
import { PointsRedemption } from './components/PointsRedemption';
import { TableSelectionInput } from './components/TableSelectionInput';
import { CancelOrderButton } from './components/CancelOrderButton';
import { useFormContext } from 'react-hook-form';

const OrderEditToolbar = () => {
  const record = useRecordContext();
  const { formState, watch } = useFormContext();
  const { isDirty } = formState;
  const items = watch('items') || [];

  // Disable save button when:
  // 1. Form is not dirty (no changes) OR
  // 2. There are no items in the order
  const canSave = isDirty && items.length > 0;

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
      <Box display="flex" gap={2}>
        <SaveButton disabled={!canSave} />
        {record?.status !== 'cancelled' && <PrintBillButton />}
      </Box>
      <Box>
        <CancelOrderButton />
      </Box>
    </Toolbar>
  );
};

const ReferenceDisplay = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="body2" color="textSecondary">
        {translate('resources.orders.fields.reference')}: {record?.reference}
      </Typography>
    </Box>
  );
};

const PromotionSection = () => {
  const translate = useTranslate();
  const { field: promotionTypeField } = useInput({ source: 'promotionType' });
  const { field: customerPointsField } = useInput({ source: 'customerPoints' });
  const { field: itemsField } = useInput({ source: 'items' });
  const { field: pointsRedemptionField } = useInput({
    source: 'pointsRedemption',
  });
  const record = useRecordContext();

  const totalAmount = (itemsField.value || []).reduce(
    (sum: number, item: { price: number; quantity: number }) =>
      sum + item.price * item.quantity,
    0
  );

  const canEditPoints =
    !record?.paid && record?.customerId && record?.status !== 'cancelled';

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">
        {translate('resources.orders.fields.promotion')}
      </Typography>
      <SelectInput
        source="promotionType"
        label="resources.orders.fields.promotionType"
        defaultValue="none"
        disabled
        fullWidth
        choices={[
          {
            id: 'none',
            name: translate('resources.orders.promotion.none'),
          },
          {
            id: 'percentage',
            name: translate('resources.orders.promotion.percentage'),
          },
          {
            id: 'fixed',
            name: translate('resources.orders.promotion.fixed'),
          },
        ]}
      />
      {promotionTypeField.value !== 'none' && (
        <NumberInput
          source="promotionValue"
          label="resources.orders.fields.promotionValue"
          disabled
          fullWidth
        />
      )}

      {promotionTypeField.value === 'none' && canEditPoints && (
        <PointsRedemption
          customerPoints={customerPointsField.value || 0}
          disabled={!canEditPoints}
          totalAmount={totalAmount}
        />
      )}
    </Box>
  );
};

export const OrderEdit = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();
  const record = useRecordContext();
  const { releaseTable } = useTableStatus();
  const notify = useNotify();
  const [update] = useUpdate();

  // Get customer data if customerId exists
  const { data: customer } = useGetOne(
    'customers',
    { id: record?.customerId },
    { enabled: !!record?.customerId }
  );

  // Release table when order is delivered or cancelled
  useEffect(() => {
    if (record && record.orderType === 'dine_in' && record.tableNumber) {
      if (record.status === 'delivered' || record.status === 'cancelled') {
        releaseTable(record.tableNumber);
      }
    }
  }, [record?.status, record?.orderType, record?.tableNumber, releaseTable]);

  const transform = (data: any) => {
    const items = data.items.map((item: any) => ({
      productId: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    // Keep existing values if not changed
    const pointsRedemption =
      data.pointsRedemption ?? record?.pointsRedemption ?? 0;
    const customerPoints = data.customerPoints ?? record?.customerPoints ?? 0;

    return {
      ...data,
      items,
      pointsRedemption,
      customerPoints,
    };
  };

  const canEditPoints =
    !record?.paid && record?.customerId && record?.status !== 'cancelled';
  const canEditOrder = !record?.paid && record?.status !== 'cancelled';

  return (
    <Edit transform={transform}>
      <SimpleForm toolbar={<OrderEditToolbar />}>
        <ReferenceDisplay />
        <SelectInput
          source="orderType"
          label="resources.orders.fields.orderType"
          choices={[
            {
              id: 'dine_in',
              name: translate('resources.orders.orderTypes.dineIn'),
            },
            {
              id: 'takeaway',
              name: translate('resources.orders.orderTypes.takeaway'),
            },
            {
              id: 'delivery',
              name: translate('resources.orders.orderTypes.delivery'),
            },
          ]}
          disabled
        />
        <TableSelectionInput disabled />
        {!isStaff && (
          <ReferenceInput source="customerId" reference="customers">
            <AutocompleteInput
              optionText={(choice?: Customer) => {
                if (!choice?.id)
                  return translate('resources.orders.messages.unknownCustomer');
                const fullName = `${choice.firstName || ''} ${
                  choice.lastName || ''
                }`.trim();
                return (
                  fullName ||
                  choice.name ||
                  translate('resources.orders.messages.unknownCustomer')
                );
              }}
              disabled
            />
          </ReferenceInput>
        )}
        <DateTimeInput
          source="date"
          label="resources.orders.fields.date"
          disabled
        />
        <ItemsGridInput disabled={!canEditOrder} />
        <OrderTotalField />
        <PromotionSection />
      </SimpleForm>
    </Edit>
  );
};

export default OrderEdit;

import * as React from 'react';
import {
  Stack,
  IconButton,
  Tooltip,
  useMediaQuery,
  Theme,
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import { useTranslate, useNotify, useRefresh } from 'react-admin';
import { Order } from '../types';
import { PrintBillButton } from './components/PrintBillButton';

interface OrderBottomActionsProps {
  record?: Order;
}

const OrderBottomActions = ({ record }: OrderBottomActionsProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const handlePayment = () => {
    // TODO: Implement payment functionality
    notify('Payment functionality coming soon', { type: 'info' });
    // When implementing payment, use refresh() after successful payment
  };

  if (!record) return null;

  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
      }}
    >
      <Tooltip
        title={translate('resources.orders.actions.make_payment')}
        placement="top"
      >
        <IconButton
          onClick={handlePayment}
          size="large"
          color="success"
          sx={{
            width: 48,
            height: 48,
          }}
        >
          <PaymentIcon />
        </IconButton>
      </Tooltip>
      <PrintBillButton variant="icon" />
    </Stack>
  );
};

export default OrderBottomActions;

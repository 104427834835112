import { Box, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import {
  NumberInput,
  useTranslate,
  Button as RaButton,
  useDataProvider,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

interface PointsRedemptionProps {
  customerPoints: number;
  totalAmount: number;
  disabled?: boolean;
  customerId?: string;
}

export const PointsRedemption = ({
  customerPoints,
  totalAmount,
  disabled,
  customerId,
}: PointsRedemptionProps) => {
  const translate = useTranslate();
  const form = useFormContext();
  const { setValue, watch } = form;
  const currentPoints = watch('pointsRedemption') || 0;
  const dataProvider = useDataProvider();
  const [availablePoints, setAvailablePoints] = useState(customerPoints);

  // Fetch available points (total points minus pending reservations)
  useEffect(() => {
    if (!customerId) return;

    const fetchAvailablePoints = async () => {
      try {
        const { data } = await dataProvider.getOne(
          'membership_points_available',
          {
            id: customerId,
          }
        );
        setAvailablePoints(data.points);
      } catch (error) {
        console.error('Error fetching available points:', error);
        setAvailablePoints(customerPoints);
      }
    };

    fetchAvailablePoints();
  }, [customerId, customerPoints, dataProvider]);

  // Watch for changes in totalAmount and adjust points if needed
  useEffect(() => {
    if (currentPoints === 0) return;

    // If total amount is less than 5,000 VND or less than current points value, reset points
    if (totalAmount < 5000 || totalAmount < currentPoints * 100) {
      setValue('pointsRedemption', 0);
      return;
    }
  }, [totalAmount, currentPoints, setValue]);

  const validatePointsRedemption = (value: number) => {
    if (!value) return undefined;

    const pointsValue = value * 100; // Convert to VND
    if (pointsValue < 5000) {
      return translate('resources.orders.messages.minPointsRedemption');
    }
    if (pointsValue > availablePoints * 100) {
      return translate('resources.orders.messages.exceedAvailablePoints');
    }
    if (pointsValue > totalAmount) {
      return translate('resources.orders.messages.insufficientOrderAmount');
    }
    if (value % 10 !== 0) {
      return translate('resources.orders.messages.pointsMultipleOf1000');
    }
    return undefined;
  };

  const handlePointsChange = (value: number) => {
    // Allow empty or valid numbers only
    if (value === undefined || value === null || isNaN(value)) {
      setValue('pointsRedemption', 0, { shouldDirty: true });
      return;
    }

    // Don't allow negative values
    if (value < 0) {
      return;
    }

    // Allow typing in progress
    setValue('pointsRedemption', value, { shouldDirty: true });
  };

  const handlePointsBlur = () => {
    let value = currentPoints;

    // If total amount is less than 5,000 VND, reset points
    if (totalAmount < 5000) {
      setValue('pointsRedemption', 0, { shouldDirty: true });
      return;
    }

    // On blur, enforce all validations
    if (value < 50) {
      value = 50;
    } else if (value * 100 > availablePoints * 100) {
      value = Math.floor(availablePoints);
    } else if (value * 100 > totalAmount) {
      value = Math.floor(totalAmount / 100);
    }

    // Round to nearest 10 points
    value = Math.floor(value / 10) * 10;
    setValue('pointsRedemption', value, { shouldDirty: true });
  };

  const handleUseMaxPoints = () => {
    // Calculate max points that can be used (must be divisible by 1000)
    const maxPointsValue =
      Math.floor(Math.min(availablePoints * 100, totalAmount) / 1000) * 1000;

    if (maxPointsValue >= 5000) {
      setValue('pointsRedemption', maxPointsValue / 100, { shouldDirty: true });
    }
  };

  if (!availablePoints) {
    return null;
  }

  // Don't show points redemption if total amount is less than 5,000 VND
  if (totalAmount < 5000) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle1">
        {translate('resources.orders.fields.points')}
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography>
          {translate('resources.orders.messages.availablePoints', {
            points: availablePoints.toLocaleString(),
          })}
        </Typography>
        <RaButton
          onClick={handleUseMaxPoints}
          label="resources.orders.actions.useMaxPoints"
          disabled={disabled}
        >
          <AddCircleIcon />
        </RaButton>
        <RaButton
          onClick={() => setValue('pointsRedemption', 0)}
          label="resources.orders.actions.resetPoints"
          disabled={disabled}
        >
          <ClearIcon />
        </RaButton>
      </Box>
      <Typography variant="body2" color="textSecondary">
        {translate('resources.orders.messages.pointsValue', {
          value: (availablePoints * 100).toLocaleString(),
        })}
      </Typography>
      <NumberInput
        source="pointsRedemption"
        label="resources.orders.fields.pointsRedemption"
        validate={validatePointsRedemption}
        helperText="resources.orders.messages.pointsRedemptionHelp"
        onChange={handlePointsChange}
        onBlur={handlePointsBlur}
        disabled={disabled}
      />
    </Box>
  );
};

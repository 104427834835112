import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  Admin,
  CustomRoutes,
  DataProvider,
  LoadingPage,
  Resource,
  StoreContextProvider,
  localStorageStore,
  useStore,
} from 'react-admin';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Route } from 'react-router';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { StaffAttendanceView } from './attendanceRecords/StaffAttendanceView';
import { attendanceRecordResource } from './attendanceRecords/StaffResource';
import authProvider from './authProvider';
import { ConfigurationEdit } from './configuration/ConfigurationEdit';
import { Dashboard } from './dashboard';
import getDataProvider from './dataProvider';
import { history } from './history';
import englishMessages from './i18n/en';
import { Layout, Login, SetNewPassword } from './layout';
import ForbiddenPage from './layout/ForbiddenPage';
import { getResourceConfigs, resources } from './resources';
import Segments from './segments/Segments';
import { StaffProfileView } from './staffProfiles/StaffProfileView';
import { staffProfileResource } from './staffProfiles/StaffResource';
import { ThemeName, themes } from './themes/themes';
import { zaloResources } from './zalo/ZaloResource';
import CheckinList from './zalo/checkin/CheckinList';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'fr') {
      return import('./i18n/fr').then((messages) => messages.default);
    }
    if (locale === 'vi') {
      return import('./i18n/vi').then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
  },
  'en',
  [
    { locale: 'en', name: 'English' },
    { locale: 'vi', name: 'Vietnamese' },
    { locale: 'fr', name: 'Français' },
  ]
);

const store = localStorageStore(undefined, 'ECommerce');
const resourcesConfigs = getResourceConfigs(resources);

const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        retry: false,
      },
    },
  });
  useEffect(() => {
    getDataProvider().then(setDataProvider);
  }, []);

  if (!dataProvider) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history as any}>
        <Admin
          title=""
          dataProvider={dataProvider}
          store={store}
          authProvider={authProvider}
          dashboard={Dashboard}
          loginPage={Login}
          layout={Layout}
          i18nProvider={i18nProvider}
          disableTelemetry
          lightTheme={themes.find((theme) => theme.name === themeName)?.light}
          darkTheme={themes.find((theme) => theme.name === themeName)?.dark}
          defaultTheme="light"
          queryClient={queryClient}
        >
          <CustomRoutes>
            <Route path="/staff_profile" element={<StaffProfileView />} />
            <Route path="/staff_attendance" element={<StaffAttendanceView />} />
            <Route path="/segments" element={<Segments />} />
            <Route path="/forbidden" element={<ForbiddenPage />} />
            <Route path="/oauth/callback" element={<LoadingPage />} />
            <Route path="/zalo/checkin" element={<CheckinList />} />
          </CustomRoutes>
          <CustomRoutes noLayout>
            <Route path="/set-new-password" element={<SetNewPassword />} />
          </CustomRoutes>
          <Resource name="staff_profiles" {...staffProfileResource} />
          <Resource name="attendance_records" {...attendanceRecordResource} />
          {Object.keys(resourcesConfigs).map((k) => (
            <Resource key={k} name={k} {...resourcesConfigs[k]} />
          ))}
          <Resource name="configuration" edit={ConfigurationEdit} />
        </Admin>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </HistoryRouter>
    </QueryClientProvider>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
);

export default AppWrapper;

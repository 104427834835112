import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import {
  BooleanField,
  DateField,
  NumberField,
  RecordContextProvider,
  TextField,
  useTranslate,
  useRedirect,
  useListContext,
  useRefresh,
  useRecordContext,
  useGetMany,
  useDataProvider,
} from 'react-admin';
import { configs } from '../configs';
import { Order } from '../types';
import OrderSideActions from './OrderSideActions';
import OrderBottomActions from './OrderBottomActions';

const StyledDateField = () => {
  const record = useRecordContext();
  const date = new Date(record.date);

  return (
    <Box component="span">
      <Typography
        component="span"
        sx={{
          fontSize: '1rem',
          color: 'text.secondary',
        }}
      >
        {date.toLocaleDateString('vi-VN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontWeight: 'bold',
          fontSize: '1.2rem',
          ml: 1,
        }}
      >
        {date.toLocaleTimeString('vi-VN', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })}
      </Typography>
    </Box>
  );
};

interface MobileGridProps {
  record: Order;
  isStaff: boolean;
}

const MobileGrid = ({ record, isStaff }: MobileGridProps) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { refetch, setFilters, filterValues } = useListContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  // Fetch all products for the items in the order
  const productIds = (record.items || [])
    .filter((item) => item && item.productId) // Filter out items with no productId
    .map((item) => item.productId.toString());

  const { data: products = [] } = useGetMany(
    'products',
    { ids: productIds },
    { enabled: productIds.length > 0 } // Only fetch if we have valid productIds
  );

  const handleRefresh = async () => {
    try {
      // Get current filter and pagination state from list context
      const { data: updatedOrders } = await dataProvider.getList('orders', {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'date', order: 'DESC' },
        filter: { status: record?.status },
      });

      // Force refresh the view
      refresh();
    } catch (error) {
      console.error('Error refreshing list:', error);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't trigger if clicking on action buttons
    if ((e.target as HTMLElement).closest('button')) {
      return;
    }
    redirect('edit', 'orders', record.id);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(value);
  };

  const calculateTotal = () => {
    return (record.items || [])
      .filter((item) => item && item.price && item.quantity) // Filter out invalid items
      .reduce((sum, item) => {
        return sum + item.price * item.quantity;
      }, 0);
  };

  const formatItems = () => {
    return (record.items || [])
      .filter((item) => item && item.productId) // Filter out invalid items
      .map((item) => {
        const product = products.find(
          (p) => p && p.id && p.id.toString() === item.productId.toString()
        );
        return product ? `${product.name} × ${item.quantity}` : '';
      })
      .filter(Boolean)
      .join(', ');
  };

  const total = calculateTotal();
  const pointsDiscount = record.pointsRedemption
    ? record.pointsRedemption * 100
    : 0;
  const finalTotal = total - pointsDiscount;

  return (
    <RecordContextProvider value={record}>
      <Card sx={{ margin: '0.5rem 0' }}>
        <Box display="flex" position="relative">
          <Box
            flex={1}
            pr={6}
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <Box p={2} pb={0}>
              <Typography variant="body1" gutterBottom>
                {translate('resources.orders.fields.reference')}: #
                {record.reference}
              </Typography>
            </Box>
            <CardContent sx={{ pt: 1 }}>
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.date')}: <StyledDateField />
              </Typography>
              {record.tableNumber && (
                <Typography variant="body2" gutterBottom>
                  {translate('resources.orders.fields.tableNumber')}:{' '}
                  <Box
                    component="span"
                    sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
                  >
                    {record.tableNumber}
                  </Box>
                </Typography>
              )}
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.items')}:{' '}
                <Box
                  component="span"
                  sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
                >
                  {formatItems()}
                </Box>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.totalBeforeDiscount')}:{' '}
                {formatCurrency(total)}
              </Typography>
              {record.pointsRedemption > 0 && (
                <Typography variant="body2" gutterBottom>
                  {translate('resources.orders.messages.pointsDiscount', {
                    points: record.pointsRedemption,
                    value: pointsDiscount,
                  })}
                </Typography>
              )}
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.total')}:{' '}
                {formatCurrency(finalTotal)}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.status')}:{' '}
                {translate(`resources.orders.tabs.${record.status}`)}
              </Typography>
              {record.status === 'delivered' && (
                <Typography variant="body2">
                  {translate('resources.orders.fields.returned')}:{' '}
                  <BooleanField source="returned" />
                </Typography>
              )}
            </CardContent>
          </Box>
          <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            display="flex"
            alignItems="stretch"
            bgcolor="background.paper"
            borderLeft="1px solid"
            borderColor="divider"
          >
            <OrderSideActions
              record={record}
              onActionComplete={handleRefresh}
            />
          </Box>
        </Box>
        <Divider />
        <CardActions sx={{ justifyContent: 'center', gap: 1, padding: 2 }}>
          <OrderBottomActions record={record} />
        </CardActions>
      </Card>
    </RecordContextProvider>
  );
};

export default MobileGrid;

import {
  useRecordContext,
  useTranslate,
  useNotify,
  useUpdate,
  useRedirect,
  useRefresh,
} from 'react-admin';
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useTableStatus } from '../hooks/useTableStatus';

interface CancelOrderButtonProps {
  variant?: 'icon' | 'button';
}

export const CancelOrderButton = ({
  variant = 'button',
}: CancelOrderButtonProps) => {
  const record = useRecordContext();
  const { releaseTable } = useTableStatus();
  const notify = useNotify();
  const [update] = useUpdate();
  const translate = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [confirmOpen, setConfirmOpen] = useState(false);

  if (record?.paid || record?.status === 'cancelled') {
    return null;
  }

  const handleCancel = async () => {
    try {
      await update(
        'orders',
        {
          id: record.id,
          data: { ...record, status: 'cancelled' },
          previousData: record,
        },
        {
          onSuccess: async () => {
            // Release table if it's a dine-in order
            if (record.orderType === 'dine_in' && record.tableNumber) {
              await releaseTable(record.tableNumber);
            }
            notify('Order cancelled successfully', { type: 'success' });
            redirect('list', 'orders');
            refresh();
          },
          onError: (error) => {
            console.error('Error cancelling order:', error);
            notify('Error cancelling order', { type: 'error' });
          },
        }
      );
    } catch (error) {
      console.error('Error handling order cancellation:', error);
      notify('Error handling order cancellation', { type: 'error' });
    }
  };

  const cancelButton =
    variant === 'icon' ? (
      <Tooltip title={translate('resources.orders.actions.cancel')}>
        <IconButton
          onClick={() => setConfirmOpen(true)}
          size="small"
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Button
        onClick={() => setConfirmOpen(true)}
        startIcon={<DeleteIcon />}
        color="error"
      >
        {translate('resources.orders.actions.cancel')}
      </Button>
    );

  return (
    <>
      {cancelButton}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          {translate('resources.orders.actions.cancel_confirm_title')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {translate('resources.orders.actions.cancel_confirm_message')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>
            {translate('common.actions.cancel')}
          </Button>
          <Button onClick={handleCancel} color="error" autoFocus>
            {translate('resources.orders.actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useInput, useTranslate, TextInput } from 'react-admin';
import { useState } from 'react';
import { TableNumberGrid } from './TableNumberGrid';

const TableSelectionDialog = ({
  open,
  onClose,
  value,
  onChange,
}: {
  open: boolean;
  onClose: () => void;
  value?: number;
  onChange: (value?: number) => void;
}) => {
  const translate = useTranslate();
  const [selectedTable, setSelectedTable] = useState<number | undefined>(value);
  const { field } = useInput({ source: 'tableNumber' });

  const handleConfirm = () => {
    onChange(selectedTable);
    field.onChange(selectedTable);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {translate('resources.orders.fields.tableNumber')}
      </DialogTitle>
      <DialogContent>
        <TableNumberGrid value={selectedTable} onChange={setSelectedTable} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TableSelectionInput = ({ disabled }: { disabled?: boolean }) => {
  const translate = useTranslate();
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const { field: orderTypeField } = useInput({ source: 'orderType' });
  const { field: tableNumberField } = useInput({ source: 'tableNumber' });

  if (orderTypeField.value !== 'dine_in') return null;

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>
          {translate('resources.orders.fields.tableNumber')}:{' '}
          {tableNumberField.value ||
            translate('resources.orders.messages.noTableSelected')}
        </Typography>
        {!disabled && (
          <Button variant="contained" onClick={() => setTableDialogOpen(true)}>
            {tableNumberField.value
              ? translate('resources.orders.actions.changeTable')
              : translate('resources.orders.actions.selectTable')}
          </Button>
        )}
      </Box>
      <TableSelectionDialog
        open={tableDialogOpen}
        onClose={() => setTableDialogOpen(false)}
        value={tableNumberField.value}
        onChange={(value) => {
          tableNumberField.onChange(value);
        }}
      />
      <TextInput source="tableNumber" style={{ display: 'none' }} />
    </>
  );
};

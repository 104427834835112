import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export const OrderTotalField = () => {
  const { watch } = useFormContext();
  const translate = useTranslate();
  const items = watch('items') || [];

  const totalExTaxes = items
    .filter((item: any) => item?.productId && item?.quantity > 0)
    .reduce((sum: number, item: any) => {
      const quantity = Number(item.quantity) || 0;
      const price = Number(item.price) || 0;
      return sum + quantity * price;
    }, 0);

  return (
    <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <Typography variant="h5" color="primary">
        {translate('resources.orders.messages.total')}:{' '}
        {new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(totalExTaxes)}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {translate('resources.orders.messages.totalBeforeDiscount')}:{' '}
        {new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(totalExTaxes)}
      </Typography>
    </Box>
  );
};

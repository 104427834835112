import * as React from 'react';
import {
  Stack,
  IconButton,
  Tooltip,
  useMediaQuery,
  Theme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useTranslate,
  useUpdate,
  useNotify,
  useDataProvider,
  useGetIdentity,
} from 'react-admin';
import { Order } from '../types';
import { useTableStatus } from './hooks/useTableStatus';
import { CancelOrderButton } from './components/CancelOrderButton';

interface OrderSideActionsProps {
  record?: Order;
  onActionComplete?: () => Promise<void>;
}

const OrderSideActions = ({
  record,
  onActionComplete,
}: OrderSideActionsProps) => {
  const translate = useTranslate();
  const [update] = useUpdate();
  const notify = useNotify();
  const { releaseTable } = useTableStatus();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const isAdmin =
    identity?.roles?.includes('sysAdmin') ||
    identity?.roles?.includes('storeManager');
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const checkActiveOrders = async (
    tableNumber: number,
    currentOrderId: string
  ) => {
    const { data: orders } = await dataProvider.getList('orders', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'DESC' },
      filter: {
        tableNumber: tableNumber.toString(),
        status: { $nin: ['delivered', 'cancelled'] },
        id: { $ne: currentOrderId.toString() },
      },
    });
    return orders.length === 0;
  };

  const handleReleaseTable = async (tableNumber: number) => {
    if (!isAdmin) return;

    if (
      !window.confirm(
        translate('resources.orders.actions.force_release_confirm')
      )
    ) {
      return;
    }

    try {
      await releaseTable(tableNumber);
      notify('Table released successfully', { type: 'success' });
      await onActionComplete?.();
    } catch (error) {
      console.error('Error releasing table:', error);
      notify('Error releasing table', { type: 'error' });
    }
  };

  const handleNextStatus = async () => {
    const statusMap: { [key: string]: string } = {
      ordered: 'ready',
      ready: 'delivered',
    };

    if (!record || !statusMap[record.status]) return;

    try {
      await update(
        'orders',
        {
          id: record.id,
          data: { ...record, status: statusMap[record.status] },
          previousData: record,
        },
        {
          mutationMode: 'pessimistic',
          onSuccess: async () => {
            notify('Order status updated successfully', { type: 'success' });
            await onActionComplete?.();
          },
          onError: (error) => {
            console.error('Error updating order status:', error);
            notify('Error updating order status', { type: 'error' });
          },
        }
      );
    } catch (error) {
      console.error('Error handling order status update:', error);
      notify('Error handling order status update', { type: 'error' });
    }
  };

  const handleSkipToDelivered = async () => {
    if (!record || record.status !== 'ordered') return;

    try {
      await update(
        'orders',
        {
          id: record.id,
          data: { ...record, status: 'delivered' },
          previousData: record,
        },
        {
          mutationMode: 'pessimistic',
          onSuccess: async () => {
            notify('Order marked as delivered', { type: 'success' });
            if (record.tableNumber) {
              const canRelease = await checkActiveOrders(
                record.tableNumber,
                record.id.toString()
              );
              if (canRelease) {
                await releaseTable(record.tableNumber);
              }
            }
            await onActionComplete?.();
          },
          onError: (error) => {
            console.error('Error marking order as delivered:', error);
            notify('Error marking order as delivered', { type: 'error' });
          },
        }
      );
    } catch (error) {
      console.error('Error handling order delivery:', error);
      notify('Error handling order delivery', { type: 'error' });
    }
  };

  const handleItemStatus = () => {
    // TODO: Implement individual item status management
    notify('Item status management coming soon', { type: 'info' });
  };

  if (!record) return null;

  return (
    <Stack
      direction={isXSmall ? 'column' : 'row'}
      spacing={1}
      sx={{
        minWidth: isXSmall ? '48px' : 'fit-content',
        height: isXSmall ? '100%' : 'auto',
        justifyContent: isXSmall ? 'space-evenly' : 'flex-start',
        alignItems: 'center',
        ...(isXSmall && { py: 2 }),
      }}
    >
      {/* Regular actions */}
      {record.status !== 'delivered' && record.status !== 'cancelled' && (
        <>
          <Tooltip
            title={translate('resources.orders.actions.next_status')}
            placement={isXSmall ? 'left' : 'top'}
          >
            <IconButton onClick={handleNextStatus} size="small" color="primary">
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          {record.status === 'ordered' && (
            <Tooltip
              title={translate('resources.orders.actions.skip_to_delivered')}
              placement={isXSmall ? 'left' : 'top'}
            >
              <IconButton
                onClick={handleSkipToDelivered}
                size="small"
                color="primary"
              >
                <FastForwardIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={translate('resources.orders.actions.item_status')}
            placement={isXSmall ? 'left' : 'top'}
          >
            <IconButton onClick={handleItemStatus} size="small">
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
          <CancelOrderButton variant="icon" />
        </>
      )}

      {/* Force release action - shown for dine-in orders with table that are not delivered */}
      {isAdmin &&
        record.tableNumber &&
        record.orderType === 'dine_in' &&
        record.status !== 'delivered' && (
          <Tooltip
            title={translate('resources.orders.actions.force_release')}
            placement={isXSmall ? 'left' : 'top'}
          >
            <IconButton
              onClick={() => handleReleaseTable(record.tableNumber!)}
              size="small"
              color="warning"
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        )}
    </Stack>
  );
};

export default OrderSideActions;

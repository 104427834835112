import { useFormContext } from 'react-hook-form';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, MouseEvent, TouchEvent, useEffect } from 'react';

export const FormDebug = ({ enabled = false }) => {
  const form = useFormContext();
  const [position, setPosition] = useState({
    x: window.innerWidth - 420,
    y: window.innerHeight - 300,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 768);
      // Adjust position if off screen after resize
      setPosition((prev) => ({
        x: Math.min(prev.x, window.innerWidth - 420),
        y: Math.min(prev.y, window.innerHeight - 100),
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
      if (!isDragging) return;

      e.preventDefault();
      e.stopPropagation();

      const clientX =
        'touches' in e ? e.touches[0].clientX : (e as MouseEvent).clientX;
      const clientY =
        'touches' in e ? e.touches[0].clientY : (e as MouseEvent).clientY;

      const newX = clientX - dragOffset.x;
      const newY = clientY - dragOffset.y;

      // Keep within window bounds
      const maxX = window.innerWidth - 420;
      const maxY = window.innerHeight - 100;

      setPosition({
        x: Math.min(Math.max(0, newX), maxX),
        y: Math.min(Math.max(0, newY), maxY),
      });
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove as any);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMouseMove as any, {
        passive: false,
      });
      window.addEventListener('touchend', handleEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove as any);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMouseMove as any);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, dragOffset]);

  const handleStart = (e: MouseEvent | TouchEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('[data-drag-handle="true"]')) return;

    e.preventDefault();
    e.stopPropagation();

    const clientX =
      'touches' in e ? e.touches[0].clientX : (e as MouseEvent).clientX;
    const clientY =
      'touches' in e ? e.touches[0].clientY : (e as MouseEvent).clientY;

    setIsDragging(true);
    setDragOffset({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  if (!enabled) return null;

  const values = form.getValues();
  const errors = form.formState.errors;
  const isDirty = form.formState.isDirty;
  const dirtyFields = form.formState.dirtyFields;
  const touchedFields = form.formState.touchedFields;

  if (isCollapsed) {
    return (
      <IconButton
        onClick={() => setIsCollapsed(false)}
        sx={{
          position: 'fixed',
          right: '16px',
          bottom: '16px',
          width: '25px',
          height: '25px',
          minWidth: '25px',
          minHeight: '25px',
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: 'white',
          padding: 0,
          zIndex: 9999,
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.9)',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        }}
      >
        <DragIndicatorIcon />
      </IconButton>
    );
  }

  return (
    <Paper
      sx={{
        position: 'fixed',
        right: '16px',
        bottom: '16px',
        width: '400px',
        maxHeight: '50vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: 'white',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 9999,
        '& pre': {
          margin: 0,
          fontSize: '12px',
        },
      }}
      elevation={3}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        data-drag-handle="true"
        sx={{
          cursor: isDragging ? 'grabbing' : 'grab',
          padding: 1,
          backgroundColor: 'rgba(0,0,0,0.9)',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          userSelect: 'none',
          flexShrink: 0,
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        <DragIndicatorIcon />
        <Typography variant="subtitle2">Form State</Typography>
        <Box flex={1} />
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsCollapsed(true);
          }}
          sx={{ color: 'white' }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: 2,
          overflowY: 'auto',
          cursor: 'auto',
          flex: 1,
          userSelect: 'text',
          '-webkit-overflow-scrolling': 'touch',
        }}
      >
        <pre>
          {JSON.stringify(
            {
              values,
              errors,
              isDirty,
              dirtyFields: Object.keys(dirtyFields),
              touchedFields: Object.keys(touchedFields),
            },
            null,
            2
          )}
        </pre>
      </Box>
    </Paper>
  );
};
